import React, { useState, useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { Navigate, useLocation } from "react-router-dom";
import postMessage from "../utils/post-message";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onAuthStateChanged(_user: any) {
      setUser(_user);
      setLoading(false);

      if (_user) {
        postMessage({
          action: "register-push-notifications",
          accessToken: _user.signInUserSession.idToken.jwtToken,
          url: `${process.env.REACT_APP_API_URL}/device-info`,
        });

        // TODO: Remove this once the mobile app is updated to request permissions
        postMessage({
          action: "request-camera-microphone-permissions",
        });

        postMessage({
          action: "request-camera-permissions",
        });
      }
    }

    Auth.currentAuthenticatedUser()
      .then(onAuthStateChanged)
      // on error, redirect to login
      .catch(() => (window.location.href = "/login"))
      .finally(() => setLoading(false));
    Auth.configure({
      onAuthStateChanged: onAuthStateChanged,
    });

    return () => {
      Auth.configure({
        onAuthStateChanged: null,
      });
    };
  }, []);

  if (loading) {
    return null;
  }

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
