import React, { useState, useRef } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import { SMSHistory } from "../classes/sms-history";
import TextAreaField from "./TextAreaField";

interface SendMessageModalProps {
  active: boolean;
  closeModal: () => void;
  message: SMSHistory;
}

export default function MessageHistoryModal({
  active,
  closeModal,
  message,
}: SendMessageModalProps) {
  return (
    <Dialog open={active} onClose={closeModal} fullWidth>
      <DialogTitle>Message</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column">
          <TextField value={message.message} multiline disabled />

          <Typography mt={4}>Recipients</Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Recipient</TableCell>
                  <TableCell>Phone Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {message.recipients.map((recipient) => (
                  <TableRow key={recipient.id}>
                    <TableCell>
                      {[
                        recipient.resident.firstName,
                        recipient.resident.lastName,
                      ]
                        .filter(Boolean)
                        .join(" ")}
                    </TableCell>
                    <TableCell>{recipient.resident.contactNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
