import { useCallback } from "react";
import {
  QueryParams,
  useApi,
  GetListInputType,
  GetListOutputType,
} from "../hooks";
import { SMSHistory } from "../classes/sms-history";

export function useSMSHistoryApi() {
  const { fetchGETList, fetchGET, fetchDELETE } = useApi();

  const getSMSHistory = useCallback(
    (id: number) => fetchGET(`sms-history/${id}`),
    [fetchGET]
  );

  const getSMSHistoryAll = useCallback(
    (queryParams?: QueryParams): Promise<GetSMSHistoryOutputType> =>
      fetchGETList("sms-history", queryParams),
    [fetchGETList]
  );

  const deleteSMSHistory = useCallback(
    (id: number) => fetchDELETE(`sms-history/${id}`),
    [fetchDELETE]
  );

  return {
    getSMSHistory,
    getSMSHistoryAll,
    deleteSMSHistory,
  };
}

export interface GetSMSHistoryInputType extends GetListInputType {
  ids?: number | number[];
}

export type GetSMSHistoryOutputType = GetListOutputType<SMSHistory>;
