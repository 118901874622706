import React, { useState, useCallback, useEffect } from "react";

import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { format } from "date-fns";

import { SMSHistory } from "../../../classes/sms-history";
import {
  GetSMSHistoryInputType,
  GetSMSHistoryOutputType,
  useSMSHistoryApi,
} from "../../../apis/sms-history";
import MessageHistoryModal from "../../../components/MessageHistoryModal";

// Define columns
const columns: ColumnDef<SMSHistory>[] = [
  {
    accessorFn: (row) => row.message,
    header: "Message",
  },
  {
    accessorFn: (row) => row.recipients.length,
    header: "Recipients",
  },
  {
    accessorKey: "createdAt",
    header: "Sent At",
    cell: (info) => {
      const value = info.getValue<string | undefined>();
      return value && format(new Date(value), "Pp");
    },
  },
];

export default function History() {
  const { getSMSHistoryAll } = useSMSHistoryApi();
  const [messages, setMessages] = useState<GetSMSHistoryOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(100);

  const [active, setActive] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<SMSHistory>(
    new SMSHistory({
      id: 1,
      message: "",
      recipients: [],
    })
  );

  const fetchData = useCallback(async () => {
    const customQuery: GetSMSHistoryInputType = {
      skip: page * take,
      take,
    };

    const _messages = await getSMSHistoryAll({ ...customQuery });

    if (page === 0) {
      setMessages(_messages);
    } else {
      setMessages({
        items: [...messages.items, ..._messages.items],
        total: _messages.total,
      });
    }
  }, [page, take]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Initialize the table instance with the data and columns
  const table = useReactTable({
    data: messages.items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Grid container flexDirection="column">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ height: "calc(100vh - 200px)" }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <React.Fragment key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableCell key={header.id}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </TableCell>
                      ))}
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => {
                        setSelectedMessage(row.original);
                        setActive(true);
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>

      <MessageHistoryModal
        active={active}
        closeModal={() => setActive(false)}
        message={selectedMessage}
      />
    </>
  );
}
