import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SendSms from "./SendSms";
import { SendSMSTabs } from "../../../components";
import History from "./SMSHistory";

export default function MessagingRoutes() {
  return (
    <>
      <SendSMSTabs />
      <Routes>
        <Route path="/send" element={<SendSms />} />
        <Route path="/history" element={<History />} />
        <Route path="*" element={<Navigate to="send" />} />
      </Routes>
    </>
  );
}
