import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { SMSRecipient } from "./sms-recipient";

export interface SMSHistoryConstructorType
  extends DatabaseObjectConstructorType {
  message: string;
  recipients: SMSRecipient[];
}

export class SMSHistory extends DatabaseObject {
  message: string;
  recipients: SMSRecipient[];

  constructor(data: SMSHistoryConstructorType) {
    super(data);

    this.message = data.message;
    this.recipients = data.recipients;
  }
}
