import React from "react";
import { NavLinkTab, NavLinkTabGroup } from "./NavLinkTab";

const SendSMSTabs = () => {
  return (
    <NavLinkTabGroup>
      <NavLinkTab to="send">Send SMS</NavLinkTab>
      <NavLinkTab to="history">History</NavLinkTab>
    </NavLinkTabGroup>
  );
};

export default SendSMSTabs;
